import React from 'react';
import { ThemeProvider, Box } from '@mui/material';
import theme from '../../theme'; // Adjust path if necessary
import Header from './Header';
import Footer from './Footer';
import Sidebar from "./Sidebar";

const layoutContainerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  width: "100vw",
  paddingTop: "64px", // Added padding to account for fixed header height
};

const headerMenuContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: theme.zIndex.appBar,
  height: "64px", // Define header height explicitly
};

const mainContentContainerStyle = {
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
};

const contentStyle = {
  flex: 1,
  overflowY: 'auto',
  padding: 2,
  boxSizing: 'border-box',
  marginLeft: 'var(--drawer-width, 0px)', // This pushes content when sidebar is open
  transition: "margin 300ms", // Smooth transition
};

const footerContainerStyle = {
  flexShrink: 0,
  width: '100%',
  backgroundColor: theme.palette.background.default,
};

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={layoutContainerStyle}>
        <Box sx={headerMenuContainerStyle}>
          <Header />
        </Box>
        <Sidebar />
        <Box sx={mainContentContainerStyle}>
          <Box sx={contentStyle}>{children}</Box>
        </Box>
        <Box sx={footerContainerStyle}>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;