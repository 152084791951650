import React, { lazy } from "react";

const HomePage = lazy(() => import("./containers/HomePage"));
const LoginPage = lazy(() => import("./containers/LoginPage"));
const RiskAnalysisPage = lazy(() => import("./containers/IpAnalysisPage"));
const OpdAnalysisPage = lazy(() => import("./containers/OpAnalysisPage"));
const CampAnalysisPage = lazy(() => import("./containers/CampAnalysisPage"));
const CampAnalysisPageWithFilters = lazy(() => import("./containers/CampAnalysisPageWithFilters"));
const PatientDetailsPage = lazy(() =>
  import("./containers/PatientDetailsPage")
);
const OpdPatientDetailsPage = lazy(() =>
  import("./containers/OpdPatientDetailsPage")
);
const CampPatientDetailsPage = lazy(() =>
  import("./containers/CampPatientDetailsPage")
);
const FileUploadProcessPage = lazy(() =>
  import("./containers/FileUploadProcessPage")
);
const ListSalesUserPage = lazy(() => import("./containers/ListSalesUserPage"));
const AnalyticsPage = lazy(() => import("./containers/AnalyticsPage"));
const SalesAgentAnalyticsPage = lazy(() =>
  import("./containers/AgentAnalyticsPage")
);
const SearchPage = lazy(() => import("./containers/SearchPage"));
const OpAnalysisPageTab = lazy(() => import("./containers/OpAnalysisPageTab"));
const WinbackAnalysisPageTab = lazy(() =>
  import("./containers/WinbackAnalysisPageTab")
);
const IpAnalysisPageTab = lazy(() => import("./containers/IpAnalysisPageTab"));
const AdminIpAnalysisPage = lazy(() =>
  import("./containers/IpAnalysisPageWithFilters")
);
const AdminOpAnalysisPage = lazy(() =>
  import("./containers/OpAnalysisPageWithFilters")
);
const RecommendationListPage = lazy(() =>
  import("./containers/RecommendationList")
);
const RecommendationDetailsPage = lazy(() =>
  import("./containers/RecommendationDetails")
);
const NonIPRecommendationsList = lazy(() =>
  import("./containers/NonIPRecommendationsList")
);
const NonIPRecommendations = lazy(() =>
  import("./containers/NonIPRecommendations")
);
const OpComprehensive = lazy(() =>
  import("./containers/OpComprehensive")
);

const HighIpPotential = lazy(() =>
  import("./containers/HighIpPotential")
);

const IpAnalyticsPage = lazy(() =>
  import("./containers/IpAnalyticsPage")
);

const AnalyticsPageWithTabs = lazy(() =>
  import("./containers/AnalyticsPageWithTabs")
);

export {
  HomePage,
  LoginPage,
  RiskAnalysisPage,
  OpdAnalysisPage,
  CampAnalysisPage,
  CampAnalysisPageWithFilters,
  PatientDetailsPage,
  OpdPatientDetailsPage,
  CampPatientDetailsPage,
  FileUploadProcessPage,
  ListSalesUserPage,
  AnalyticsPage,
  AnalyticsPageWithTabs,
  SalesAgentAnalyticsPage,
  SearchPage,
  OpAnalysisPageTab,
  IpAnalysisPageTab,
  WinbackAnalysisPageTab,
  AdminIpAnalysisPage,
  AdminOpAnalysisPage,
  RecommendationListPage,
  RecommendationDetailsPage,
  NonIPRecommendations,
  NonIPRecommendationsList,
  OpComprehensive,
  HighIpPotential,
  IpAnalyticsPage
};
