import home from "../images/home.png";
import discharge from "../images/discharge.png";
import doctor from "../images/opd.png";
import ipPotential from "../images/IP Potential.png";
import ipd from "../images/ipd.png";
import camp from "../images/camp.png";
import winback from "../images/win_back.png";
import search from "../images/search_patient.png";
import analytics from "../images/Analytics.png";

const ICONS = {
  home,
  discharge,
  doctor,
  ipPotential,
  ipd,
  camp,
  winback,
  search,
  analytics,
};

export default ICONS;
